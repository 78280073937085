import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import slkspace from "../../Assets/Projects/slkspace.png";
import TMDD from "../../Assets/Projects/TMDD.jpeg";
import youtube from "../../Assets/Projects/youtube.png";
import ThreeTacToe from "../../Assets/Projects/3ttWhite.png";
import RunLiuk from "../../Assets/Projects/RunLiuk.png";
import LogoEraOne from "../../Assets/Projects/LogoEraOne.png";
function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={LogoEraOne}
              isBlog={false}
              title="ERA ONE"
              description="Collaboration with Team Complex as VFX and Technical Artist for this multiplayer RTS High Definition RP game. Working on visual effects and technical implementation to enhance the gaming experience."
              ghLink="https://store.steampowered.com/app/2509200/ERA_ONE/"
              demoLink="https://www.teamcomplex.co.uk/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ThreeTacToe}
              isBlog={false}
              title="3 Tac Toe"
              description="A revolutionary take on the classic Tic Tac Toe! Experience the game in three dimensions, bringing a whole new level of strategy and fun. Available for mobile and tablet devices (iOS & Android)."
              ghLink="/3TacToe"
              demoLink="/3TT/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={slkspace}
              isBlog={false}
              title="SLK Space"
              description="An immersive space shooter survival game featuring 10 challenging levels. Master both 2D and 3D gameplay mechanics in this thrilling adventure. Available for mobile and tablet (iOS & Android)."
              ghLink="/app"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={RunLiuk}
              isBlog={false}
              title="Run Liuk"
              description="Jump into this addictive casual game that combines simple controls with engaging gameplay. Perfect for quick gaming sessions on your mobile device (Available for Android)."
              ghLink="https://play.google.com/store/apps/details?id=com.DefaultCompany.testrunliuk"
              //demoLink="/games"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={TMDD}
              isBlog={false}
              title="The Maimed Dumb Duck"
              description="A unique collection of NFT collectibles featuring 3D colored ducks with special gaming perks. Each duck brings its own unique abilities to enhance your gaming experience (OpenSea Collection)."
              ghLink="https://opensea.io/collection/the-sanliuk-maimeddumbduck"
              demoLink="/TMDD"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={youtube}
              isBlog={false}
              title="YouTube"
              description="Join my creative journey on YouTube! Watch new animations, music videos, and stay updated with the latest news and projects."
              ghLink="https://www.youtube.com/channel/UCgbG6HYcITXhZ0W35lUvFbA"
            />
          </Col>



        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
