import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/sanliuk1.png";

import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={7} xs={12} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
              <div style={{ padding: 50, textAlign: "left" }}>
                <Type />
              </div>
              </h1>

              <h1 className="heading-name">
                Hej
                <strong className="main-name"> Liuk </strong> :) <br />
                Don't forget to draw your dreams!
              </h1>

            </Col>

            <Col md={5} xs={12} style={{ paddingBottom: 20, textAlign: "center" }}>
              <img
                src={homeLogo}
                alt="home pic"
                className="imagehome"
                style={{ maxHeight: "450px", width: "100%", height: "auto" }}
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Home2 />
    </section>
  );
}

export default Home;
