import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Github from "./Github";
import Techstack from "./Techstack";
import Aboutcard from "./AboutCard";
import aboutImg from "../../Assets/about2.jpg";
import { MdEmail } from "react-icons/md"; // Import the email icon

import Toolstack from "./Toolstack";

function About() {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              Know Who <strong className="purple">I am </strong>
              Since the Beginning
            </h1>
            <Aboutcard />
          </Col>
          <Col
              md={5}
              style={{ paddingTop: "120px", paddingBottom: "50px" ,     position: "relative",  // Ensure the container is positioned correctly
              }}
              className="about-img"
              
              >
                <img
                  src={aboutImg}
                  alt="about"
                  className="img-fluid"
                  style={{
                    borderRadius: "5%", // Rende l'immagine circolare
                    border: "3px solid #E09700", // Bordo blu luminoso
                    objectFit: "cover", // Assicura che l'immagine copra l'area senza distorcersi
                    width: "400px", // Larghezza dell'immagine, regolabile a piacere
                    height: "500px" // Altezza dell'immagine, deve essere uguale alla larghezza per un cerchio perfetto
                  }}
                />

                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                              <a
                                href="mailto:info@sanliuk.com"
                                style={{
                                  color: '#E09700',
                                  fontSize: '1.2em',
                                  textDecoration: 'none',
                                }}
                              >
                                <MdEmail style={{ marginLeft: '8px' }} /> {/* Add icon with spacing */}

                                info@sanliuk.com

                              </a>
                </div>
          </Col>


        </Row>
        <h1 className="project-heading">
          Professional <strong className="purple">Skillset </strong>
        </h1>

        <Techstack />

        <h1 className="project-heading">
          <strong className="purple">Tools</strong> I use
        </h1>
        <Toolstack />

        <Github />
      </Container>
    </Container>
  );
}

export default About;
